import React, {PureComponent} from "react";
import Template from "../template/Template";

class NotFound extends PureComponent {
    render() {
        return (
            <Template>404 Not Found...</Template>
        );
    }
}

export default NotFound;